'use strict';
var config = function($window,$log) {
  this.$window=$window;
  this.$log=$log;
  this.hosts={
    'localhost': {
      'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      // 'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.simu.sips-atos.com/paymentInit',
      'ctepHttpProtocol':'https',
      'imageService':'http://localhost/outdoor/src/services/templateimage.php',
      // 'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepWsProtocol':'wss',
      'devIsAvailable':true,
      'ctepScript':'ctepForKiosk',
      'undefinedRefererRedirectUrl': {
        'en' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/formules-gastronomiques-durbuy-_4bebd65490a744e996c7c674e7cd9968',
        'fr' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/formules-gastronomiques-durbuy-_4bebd65490a744e996c7c674e7cd9968',
        'nl' : 'https://www.cera.coop/nl/particulieren/genieten-van-voordelen/voordelen-zoeken/durbuy-arrangementen_4bebd65490a744e996c7c674e7cd9968'
      }
    },
    'kiosk': {
      'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':false,
      'ctepScript':'ctepForKiosk'
    },
    '192': {
      'serviceUrl':'http://192.168.1.52:32/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit'
    },
    'dev': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'www': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'outdoorpub': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit'
    },
    'outdoor': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':false,
      'ctepScript':'ctepForKiosk'
    },
    'adventure-valley': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'advv': {
      'serviceUrl':'http://localhost/outdoor/src/services/index.php',
      'sipsUrl':'https://payment-webinit.simu.sips-services.com/paymentInit',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'ctepHttpProtocol':'https',
      'ctepWsProtocol':'wss',
      'devIsAvailable':true,
      'ctepScript':'ctepForKiosk'
    },
    'durbuyfestival': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'takeawaybybru': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'reservation': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'lunch': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'breakfast': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'booking': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'tml': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'stages': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    },
    'cera': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit',
      'undefinedRefererRedirectUrl': {
        'en' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'fr' : 'https://www.cera.coop/fr/particuliers/profitez-des-avantages/chercher-un-avantage/arrangements-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf',
        'nl' : 'https://www.cera.coop/nl/particulieren/genieten-van-voordelen/voordelen-zoeken/verwennerij-in-durbuy_b809c1d4656d4548b1b7e39e0f96bdbf'
      }
    },
    'glamping': {
      'serviceUrl':'https://outdoor.lapetitemerveille.be/services/index.php',
      'imageService':'https://outdoor.lapetitemerveille.be/services/templateimage.php',
      'sipsUrl':'https://payment-webinit.sips-services.com/paymentInit'
    }
  };
  this.host=null;
  this.langs=['en','nl','fr'];
};
config.prototype.get = function(key) {
  var host=this.getHost();
  return host[key];
};
config.prototype.set = function(key,value) {
  var host=this.getHost();
  host[key]=value;
};
config.prototype.getHost = function() {
  if(this.host===null) {
    var hostname=this.$window.location.hostname;
    if(hostname.match(/^www\./)) {
      hostname=hostname.substr(4);
    }
    hostname=hostname.split('.')[0];
    if(hostname in this.hosts) {
      this.host=this.hosts[hostname];
    } else {
      this.$log.log('ERROR: domain <'+hostname+'> is not configured');
    }
  }
  return this.host;
};
angular.module('app').service('config',['$window','$log',config]);
