'use strict';
var ctepWrapper = function($q,$log,$websocket,$timeout,config,modelstore,webservice,tools,user,errorlog,toastr) {
  this.$q=$q;
  this.$log=$log;
  this.$websocket=$websocket;
  this.$timeout=$timeout;
  this.config=config;
  this.modelstore=modelstore;
  this.webservice=webservice;
  this.tools=tools;
  this.user=user;
  this.errorlog=errorlog;
  this.toastr=toastr;
  this.script='CtepForBrowser';
  this.httpPort='11773';
  this.wsPort='11774';
  this.sockets={};
  this.availableTerminalidAndIPs={};
  this.ctepterminals={};
  this.availableCtepterminals={};
  this.myIp='';
  this.claimFirstTerminalIsActive=false;
  this.onTerminalIsClaimed=null;
  this.onTerminalIsUnavailable=null;
  this.onSocketClose=null;
  this.claimedCtepterminal=null;
  this.backupCtepterminals={};
  this.status='idle';
  this.refreshing=false;
  this.cancelerror=false;
  this.setstatusPromise=null;
  this.verifying=false;
  this.saleVisit=null;
  this.saleModel={};
  this.saleDeferred=null;
};
ctepWrapper.prototype.getServerIp = function() {
  var ctep=this;
  return ctep.availableTerminalidAndIPs[ctep.claimedCtepterminal.terminalid].ip;
};
ctepWrapper.prototype.http = function(method,url,params) {
  var ctep=this;
  ctep.$log.log('ctep HTTP calling '+method+' '+url+' '+angular.toJson(params));
  return ctep.webservice.http(method,ctep.config.get('ctepHttpProtocol')+'://'+ctep.getServerIp()+':'+ctep.httpPort+url,angular.toJson(params),{}).then(function(response) {
    if(response) {
      ctep.$log.log('ctep HTTP resolving '+method+' '+url+' '+angular.toJson(params));
    }
    return response;
  });
};
ctepWrapper.prototype.get=function(url) {
  return this.http('GET',url,'');
};
ctepWrapper.prototype.post=function(url,params) {
  return this.http('POST',url,params);
};
ctepWrapper.prototype.send=function(params) {
  var ctep=this;
  ctep.$log.log('service WS sending '+angular.toJson(params));
  ctep.sockets[ctep.getServerIp()].send(angular.toJson(params));
};
ctepWrapper.prototype.updateCtepterminals = function() {
  var ctep=this;
  var i,ctepterminal;
  ctep.availableCtepterminals={};
  for(i in ctep.ctepterminals) {
    ctepterminal=ctep.ctepterminals[i];
    if(ctepterminal.terminalid in ctep.availableTerminalidAndIPs) {
      ctep.availableCtepterminals[ctepterminal.id]=ctepterminal;
    }
  }
  var terminalWasClaimed=ctep.claimedCtepterminal!==null;
  ctep.claimedCtepterminal=null;
  if(ctep.claimFirstTerminalIsActive) {
    if(ctep.tools.getLength(ctep.availableCtepterminals)>=1) {
      ctep.claimedCtepterminal=ctep.tools.first(ctep.availableCtepterminals);
      if(angular.isFunction(ctep.onTerminalIsClaimed) && !terminalWasClaimed) {
        ctep.onTerminalIsClaimed();
      }
    }
  } else {
    for(i in ctep.availableCtepterminals) {
      ctepterminal=ctep.availableCtepterminals[i];
      if(ctepterminal.user===ctep.user.id) {
        ctep.claimedCtepterminal=ctepterminal;
      }
    }
  }
  if(angular.isFunction(ctep.onTerminalIsUnavailable) && terminalWasClaimed && ctep.claimedCtepterminal===null) {
    ctep.onTerminalIsUnavailable();
  }
};
ctepWrapper.prototype.handleMessageCtepterminals = function(ip,availableTerminalids) {
  var ctep=this;
  var i,availableTerminalid,matchingCtepterminals;
  for(i in ctep.availableCtepterminals) {
    if(ctep.availableTerminalidAndIPs[ctep.availableCtepterminals[i].terminalid].ip===ip) {
      delete ctep.availableCtepterminals[i];
    }
  }
  for(i in ctep.availableTerminalidAndIPs) {
    if(ctep.availableTerminalidAndIPs[i].ip===ip) {
      delete ctep.availableTerminalidAndIPs[i];
    }
  }
  for(i in availableTerminalids) {
    availableTerminalid=availableTerminalids[i];
    ctep.availableTerminalidAndIPs[availableTerminalid]={'ip':ip,'terminalid':availableTerminalid};
    if(ctep.claimWhenAvailable==='') {
      matchingCtepterminals=ctep.tools.match(ctep.ctepterminals,{'terminalid':availableTerminalid});
      if(ctep.tools.getLength(matchingCtepterminals)<1) {
        ctep.webservice.call('createCtepterminal',{'ctepterminal':{'terminalid':availableTerminalid}},ctep.config.get('ctepScript'),ctep).then(function(response) {
          if(response) {
            ctep.updateCtepterminals();
          }
        });
      }
    } else {
      ctep.modelstore.addRecord('ctepterminals',{'id':ctep.tools.getMinIdMinusOne(ctep.ctepterminals),'terminalid':availableTerminalid,'label':availableTerminalid,'user':'0','printtickets':'0'},ctep);
    }
  }
  ctep.updateCtepterminals();
};

ctepWrapper.prototype.handleMessageCtepsaleresult = function(ip,ctepsaleresult) {
  var ctep=this;
  ctepsaleresult.sender='browser';
  var errorWording='';
  ctep.webservice.call('createCtepsaleresult',{'ctepsaleresult':ctepsaleresult},ctep.config.get('ctepScript'),ctep.saleModel).then(function(response) {
    if(ctep.verifying) {
      ctep.$timeout(function() {
        ctep.verifying=false;
      },1000);
    }
    if('cause' in ctepsaleresult && ctepsaleresult.cause==='status') {
      var triedGettingVisit;
      if(ctepsaleresult.ctepsale!=='' && ctepsaleresult.ctepsale!=='0') {
        triedGettingVisit=ctep.webservice.call('getVisit',{'ctepsale':ctepsaleresult.ctepsale},ctep.config.get('ctepScript'),ctep);
      } else {
        triedGettingVisit=ctep.$q.resolve(false);
      }
      triedGettingVisit.then(function(visit) {
        if(ctepsaleresult.action==='success' && parseFloat(ctepsaleresult.authorizedamount)>0.0) {
          var amountString=ctep.tools.amountOut(ctep.tools.amountIn(ctepsaleresult.authorizedamount))+'€';
          if(visit) {
            amountString+=' '+ctep.tools.lang({'en':'for','nl':'voor','fr':'pour'})+' '+visit.name;
          }
          if(visit && ctep.saleVisit!==null && visit.id===ctep.saleVisit.id) {
            ctep.toastr.success(amountString);
          } else {
            ctep.toastr.warning(ctep.tools.lang({
              'en':'Successful previous payment does not match the last payment command.',
              'nl':'Succesvolle vorige betaling komt niet overeen met het laatste betalingscommando.',
              'fr':'Paiement r\u00E9ussi ne correspond pas a la demande de paiement pr\u00E9c\u00E9dente.'
            })+' '+amountString);
          }
        } else {
          errorWording=ctepsaleresult.errorcode+' '+ctepsaleresult.errordescription;
          if(errorWording===' ') {
            ctep.toastr.error(ctep.tools.lang({'en':'Invalid previous payment','nl':'Ongeldige vorige betaling','fr':'Paiement pr\u00E9c\u00E9dent non valable'}));
          } else {
            ctep.toastr.error(errorWording);
          }
        }
      });
    } else {
      if(ctepsaleresult.action==='success') {
        ctep.setstatus('idle');
      } else {
        errorWording=ctepsaleresult.errordescription;
        if(ctepsaleresult.errorcode==='1803') {
          errorWording='Timeout expiration';
        } else if(ctepsaleresult.errorcode==='2629') {
          errorWording='User cancellation';
        } else if(ctepsaleresult.errorcode==='1802') {
          errorWording='Unexpected message, terminal busy';
        } else if(ctepsaleresult.errorcode==='2630') {
          errorWording='Device cancellation';
        }
        ctep.toastr.error(errorWording);
        ctep.setstatus('cancelling',6000);
      }
      ctep.saleDeferred.resolve(response);
    }
  });
};

ctepWrapper.prototype.handleMessageCancelretailerror = function() {
  var ctep=this;
  if(ctep.status==='cancelling') {
    ctep.setstatus('cancelerror');
  }
};
ctepWrapper.prototype.refresh=function() {
  var ctep=this;
  if(!ctep.refreshing) {
    ctep.refreshing=true;
    ctep.webservice.call('getServicesAndTerminals',{},ctep.config.get('ctepScript'),ctep).then(function(response) {
      if(response) {
        ctep.updateCtepterminals();
        var promises=[];
        for(var i in response) {
          promises.push(ctep.queryCtepServer(response[i].ip));
        }
        ctep.$q.all(promises).then(function() {
          ctep.refreshing=false;
        });
      }
    });
  }
};

ctepWrapper.prototype.claimMyTerminal = function() {
  var ctep=this;
  ctep.claimFirstTerminalIsActive=true;
  if(!ctep.refreshing) {
    ctep.refreshing=true;
    ctep.queryCtepServer('outdoorctep').then(function() {
      ctep.refreshing=false;
    });
  }
};

ctepWrapper.prototype.queryCtepServer = function(ip) {
  var ctep=this;
  return ctep.webservice.http('GET',ctep.config.get('ctepHttpProtocol')+'://'+ip+':'+ctep.httpPort+'/running').then((function() {
    return function(response) {
      var result;
      if(response) {
        if(!(ip in ctep.sockets)) {
          var socket=ctep.$websocket(ctep.config.get('ctepWsProtocol')+'://'+ip+':'+ctep.wsPort);
          socket.onClose(function() {
            ctep.delistSocket(ip);
          });
          socket.onMessage(function(message) {
            ctep.webservice.handleResponse('ctep WS',angular.fromJson(message.data),{}).then(function(response) {
              ctep.$log.log('ctep WS resolving '+angular.toJson(response));
              for(var i in response) {
                var messageHandler='handleMessage'+i.charAt(0).toUpperCase() + i.slice(1);
                if(angular.isFunction(ctep[messageHandler])) {
                  ctep[messageHandler](ip,response[i]);
                }
              }
            });
          });
          ctep.sockets[ip]=socket;
        }
        result=true;
      } else {
        result=false;
      }
      return ctep.$q.resolve(result);
    };
  })(),function() {
    return ctep.$q.resolve(false);
  });
};

ctepWrapper.prototype.delistSocket = function(ip) {
  var ctep=this;
  delete ctep.sockets[ip];
  ctep.invalidateServer(ip);
  if(angular.isFunction(ctep.onSocketClose)) {
    ctep.onSocketClose();
  }
};

ctepWrapper.prototype.editCtepterminal = function(ctepterminal) {
  var ctep=this;
  ctep.backupCtepterminals[ctepterminal.id]=ctep.tools.clone(ctepterminal);
  ctepterminal.editing=true;
};

ctepWrapper.prototype.updateCtepterminal = function(ctepterminal) {
  var ctep=this;
  ctep.webservice.call('updateCtepterminal',{'ctepterminal':ctepterminal},ctep.config.get('ctepScript'),ctep).then(function(response) {
    if(response) {
      ctep.updateCtepterminals();
    }
  });
};

ctepWrapper.prototype.cancelCtepterminal = function(ctepterminal) {
  var ctep=this;
  ctep.modelstore.addRecord('ctepterminals',ctep.backupCtepterminals[ctepterminal.id],ctep);
  ctep.updateCtepterminals();
};

ctepWrapper.prototype.claimCtepterminal = function(ctepterminal) {
  var ctep=this;
  ctep.webservice.call('claimCtepterminal',{'id':ctepterminal.id},ctep.config.get('ctepScript'),ctep).then(function(response) {
    if(response) {
      ctep.updateCtepterminals();
    }
  });
};

ctepWrapper.prototype.unclaimCtepterminal = function() {
  var ctep=this;
  ctep.webservice.call('unclaimCtepterminal',{},ctep.config.get('ctepScript'),ctep).then(function(response) {
    if(response) {
      ctep.updateCtepterminals();
    }
  });
};

ctepWrapper.prototype.invalidateServer = function(ip) {
  var ctep=this;
  ip=ip||ctep.getServerIp();
  for(var i in ctep.availableTerminalidAndIPs) {
    if(ctep.availableTerminalidAndIPs[i].ip===ip) {
      delete ctep.availableTerminalidAndIPs[i];
    }
  }
  ctep.updateCtepterminals();
};

ctepWrapper.prototype.ctepMessage = function(message,params,model) {
  var ctep=this;
  if(angular.isUndefined(model)) {
    model=ctep;
  }
  return ctep.post('/ctepterminals/'+ctep.claimedCtepterminal.terminalid+'/nonces',{}).then(function(response) {
    var result1;
    if(response) {
      params.nonce=response;
      result1=ctep.webservice.call(message,params,ctep.config.get('ctepScript'),model).then(function(response) {
        var result2;
        if(response) {
          result2=ctep.send(response);
        } else {
          ctep.errorlog.error(ctep.tools.lang({'en':'Terminal was linked by someone else','nl':'Terminal werd gelinkt door iemand anders','fr':'Terminal etait li\u00E9 par quelqu\u0027un d\u0027autre'}));
          ctep.updateCtepterminals();
          result2=ctep.$q.reject();
        }
        return result2;
      });
    } else {
      ctep.invalidateServer();
      result1=ctep.$q.reject();
    }
    return result1;
  },function() {
    ctep.invalidateServer();
    return ctep.$q.reject();
  });
};

ctepWrapper.prototype.startPayment = function(visit,amount,saleModel) {
  var ctep=this;
  ctep.setstatus('paymentStarted');
  return ctep.ctepMessage('signStartPayment',{'visitId':visit.id,'amount':amount,'terminalid':ctep.claimedCtepterminal.terminalid,'printtickets':ctep.claimedCtepterminal.printtickets},saleModel).then(function() {
    ctep.setstatus('paymentStarted');
    ctep.saleVisit=visit;
    ctep.saleModel=saleModel;
    ctep.saleDeferred=ctep.$q.defer();
    return ctep.saleDeferred.promise;
  },function() {
    ctep.setstatus('idle');
    return ctep.$q.reject();
  });
};

ctepWrapper.prototype.cancelPayment = function() {
  var ctep=this;
  ctep.setstatus('cancelling',7000);
  ctep.ctepMessage('signCancelRetailTransaction',{'terminalid':ctep.claimedCtepterminal.terminalid}).then(function() {},function() {
    ctep.setstatus('idle');
  });
};

ctepWrapper.prototype.lastTransaction = function() {
  var ctep=this;
  ctep.verifying=true;
  ctep.ctepMessage('signLastTransactionStatus',{'terminalid':ctep.claimedCtepterminal.terminalid}).then(function() {},function() {
    ctep.verifying=false;
  });
};

ctepWrapper.prototype.setstatus = function(status,time) {
  var ctep=this;
  if(angular.isUndefined(time)) {
    time=45000;
  }
  if(ctep.setstatusPromise!==null) {
    ctep.$timeout.cancel(ctep.setstatusPromise);
    ctep.setstatusPromise=null;
  }
  ctep.status=status;
  if(status!=='idle') {
    ctep.setstatusPromise=ctep.$timeout(function() {
      ctep.setstatus('idle');
    },time);
  }
};

angular.module('app').service('ctep',['$q','$log','$websocket','$timeout','config','modelstore','webservice','tools','user','errorlog','toastr',ctepWrapper]);
