angular.module('app', ['ngAnimate','ui.router','ui.bootstrap','ui.scrollpoint','toastr','ngWebSocket','ngTouch','ngIdle','bc.AngularKeypad','angularRipple']);

angular.module('app').config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);

angular.module('app').config(['toastrConfig',function(toastrConfig) {
  angular.extend(toastrConfig, {
    'positionClass':'toast-top-full-width',
    'timeout':'7000'
  });
}]);

angular.module('app').config(['IdleProvider', function (IdleProvider) {
  IdleProvider.idle(20); // in seconds
  IdleProvider.timeout(20); // in seconds
  IdleProvider.autoResume('notIdle');
}]);

/** @ngInject */
angular.module('app').config(['$stateProvider','$urlRouterProvider',function($stateProvider, $urlRouterProvider) {
  // $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      url: '/',
      template: ' ',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('finished', {
      url: '/finished/:campaignCode/:state',
      template: ' ',
      controller:['$scope','$stateParams','stateService',function($scope,$stateParams,stateService) {
        $scope.model.sipsPaymentState=$stateParams.state;
        stateService.setState({});
      }]
    })
    .state('train', {
      url: '/train',
      template: ' ',
      controller:['$scope','stateService',function($scope,stateService) {
        $scope.model.specificSaleFormbuttonId='5';
        stateService.setState({'specificSaleFormbuttonId':'5'});
      }]
    })
    .state('kayak', {
      url: '/kayak',
      template: ' ',
      controller:['$scope','stateService',function($scope,stateService) {
        $scope.model.specificSaleFormbuttonId='67';
        stateService.setState({'specificSaleFormbuttonId':'67'});
      }]
    })
    .state('singleproduct', {
      url: '/singleproduct/:formbuttonId',
      template: ' ',
      controller:['$window','$stateParams','stateService',function($window,$stateParams,stateService) {
        $window.outdoorArgs.formbutton=$stateParams.formbuttonId;
        stateService.setState({'formbutton':$stateParams.formbuttonId});
      }]
    })
    .state('promo', {
      url: '/promoCode/:promoCode',
      template: ' ',
      controller:['$window','$stateParams','stateService',function($window,$stateParams,stateService) {
        $window.outdoorArgs.promoCode=$stateParams.promoCode;
        stateService.setState({'promoCode':$stateParams.promoCode});
      }]
    })
    .state('select', {
      url: '/select/:lang/:formbuttonId/:promoCode',
      template: ' ',
      controller:['$window','$stateParams','user','stateService',function($window,$stateParams,user,stateService) {
        user.lang=$stateParams.lang;
        $window.outdoorArgs.formbutton=$stateParams.formbuttonId;
        $window.outdoorArgs.promoCode=$stateParams.promoCode;
        stateService.setState({'lang':$stateParams.lang,'formbutton':$stateParams.formbuttonId,'promoCode':$stateParams.promoCode});
      }]
    })
    .state('setup', {
      url: '/setup',
      templateUrl: 'app/views/setupPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('languages', {
      url: '/languages',
      templateUrl: 'app/views/languagesPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('language', {
      url: '/language/:lang',
      template: ' ',
      controller:['$scope','$stateParams','user','stateService',function($scope,$stateParams,user,stateService) {
        user.lang=$stateParams.lang;
        stateService.setState({});
      }]
    })
    .state('dates', {
      url: '/dates',
      templateUrl: 'app/views/datesPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('activities', {
      url: '/activities',
      templateUrl: 'app/views/activitiesPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('credentials', {
      url: '/credentials',
      templateUrl: 'app/views/credentialsPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('paymentInstructions', {
      url: '/paymentInstructions',
      templateUrl: 'app/views/paymentInstructionsPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('kioskFinished', {
      url: '/kioskFinished',
      templateUrl: 'app/views/kioskFinishedPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('detailsPage', {
      url: '/detailsPage',
      templateUrl: 'app/views/detailsPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('notAvailableToday', {
      url: '/notAvailableToday',
      templateUrl: 'app/views/notAvailableTodayPage.html',
      controller:['stateService',function(stateService) {
        stateService.setState({});
      }]
    })
    .state('around-the-world-weekend', {
      url: '/around-the-world-weekend/:lang',
      template: ' ',
      controller:['$scope','$stateParams','user','stateService',function($scope,$stateParams,user,stateService) {
        $scope.model.fixedDateInterval={'startdate':'2020-07-24','enddate':'2020-07-27','hraPerHotel':{
          'SPH':'SPH168','ADV':'ADV183'
        }};
        user.lang=$stateParams.lang;
        stateService.setState({});
      }]
    })
    // .state('kiosk', {
    //   url: '/kiosk/:ip/:terminalid',
    //   template: ' ',
    //   controller:['$stateParams','ctep',function($stateParams,ctep) {
    //     ctep.claimWhenAvailable=$stateParams.terminalid;
    //     ctep.queryCtepServer($stateParams.ip);
    //   }]
    // })
    ;
}]);
