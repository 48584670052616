angular.module('app').controller('LanguagesPageController',['$scope','tools','user','Idle',
                                            function($scope,tools,user,Idle) {
  var model=$scope.model;

  $scope.selectLanguage = function(lang) {
    $scope.resetModel();
    user.lang=lang;
    $scope.onDateChange('startdate',model.startdate.getTime()).then(function() {
      Idle.watch();
      $scope.redirectToActivities();
    });
  };

  Idle.unwatch();

}]);
