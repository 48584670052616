angular.module('app').controller('UseVoucherModalController',['$scope','$timeout','webservice','tools','errorlog',
                                            function($scope,$timeout,webservice,tools,errorlog) {
  var script='public/reservationC';

  $scope.newVoucherCode = function() {
    return {'code':'','timeout':null,'status':'idle','signature':'','value':''};
  };

  $scope.validateVoucher = function($index) {
    var voucherCodeA=$scope.voucherCodes[$index];
    var voucherid=voucherCodeA.code.replace(/[^0-9]/g,'');
    if((voucherid.length===12 || /\d{3}500/.test(voucherid)) && voucherCodeA.timeout===null) {
      voucherCodeA.status='calling';
      webservice.call('validateVoucher',{'index':$index,'voucherid':voucherid},script,{}).then(function(response) {
        if(response!==false) {
          var voucherCodeB=$scope.voucherCodes[response.index];
          if('error' in response) {
            voucherCodeB.status='invalid';
            errorlog.error(tools.lang({'en':'Invalid voucher ID','nl':'Ongeldige geschenkbon ID','fr':'ID cheque cadeau invalide'}));
          } else if('timeout' in response) {
            voucherCodeB.status='calling';
            voucherCodeB.timeout=$timeout(function(passIndex) {
              $scope.voucherCodes[passIndex].timeout=null;
              $scope.validateVoucher(passIndex);
            },response.timeout,true,response.index);
          } else {
            voucherCodeB.status='valid';
            voucherCodeB.signature=response.signature;
            voucherCodeB.value=response.value;
          }
        }
      });
    } else {
      voucherCodeA.status='idle';
    }
  };

  $scope.vouchersAreInvalid = function() {
    var result=false;
    for(var i in $scope.voucherCodes) {
      if($scope.voucherCodes[i].code.length>0 && $scope.voucherCodes[i].status!=='valid') {
        result=true;
        break;
      }
    }
    return result;
  };

  $scope.close = function() {
    $scope.$close(tools.whitelistNoId(tools.match($scope.voucherCodes,{'status':'valid'}),['code','signature']));
  };

  $scope.voucherCodes=[$scope.newVoucherCode()];
}]);
