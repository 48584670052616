'use strict';
var localStorageWrapper = function($window) {
  this.$window=$window;
};
localStorageWrapper.prototype.isSupported = function() {
  try {
    return ('localStorage' in this.$window && this.$window.localStorage !== null);
  } catch (e) {
    return false;
  }
};
localStorageWrapper.prototype.set = function(key,value) {
  if (!this.isSupported()) {
    return;
  }
  if (angular.isUndefined(value)) {
    value = null;
  }
  if (angular.isObject(value) || angular.isArray(value)) {
    if(value!==null && angular.isDate(value)) {
      value=value.getFullYear()+'-'+('0'+(value.getMonth()+1)).slice(-2)+'-'+('0'+value.getDate()).slice(-2);
    } else {
      value = angular.toJson(value);
    }
  }
  localStorage.setItem(key, value);
};
localStorageWrapper.prototype.get = function(key) {
  if (!this.isSupported()) {
    return null;
  }
  var value = localStorage.getItem(key);
  if(!value || value==='null') {
    return null;
  }
  // if(value.match(/\d{4}-[01]\d-[0-3]\d/)) {
  //   return new Date(value.substr(0,4),value.substr(5,2)-1,value.substr(8,2));
  // }
  if (value.charAt(0) === '{' || value.charAt(0) === '[') {
    return angular.fromJson(value);
  }
  return value;
};
localStorageWrapper.prototype.clear = function() {
  if (!this.isSupported()) {
    return;
  }
  for (var key in localStorage) {
    localStorage.removeItem(key);
  }
};
localStorageWrapper.prototype.bind = function($scope,pathInScope,nameInLocalStorage,defaultValue) {
  var localStorageService=this;
  var value=localStorageService.get(nameInLocalStorage);
  if(value===null) {
    value=defaultValue;
  }
  var scopeObject=$scope;
  var nameInScope='';
  var pathInScopeLength=pathInScope.length;
  for(var i=0;i<pathInScopeLength;i++) {
    if(i<pathInScopeLength-1) {
      scopeObject=scopeObject[pathInScope[i]];
    } else {
      scopeObject[pathInScope[i]]=value;
    }
    nameInScope+='.'+pathInScope[i];
  }
  nameInScope=nameInScope.substr(1);
  $scope.$watch(nameInScope,function(newValue) {
    localStorageService.set(nameInLocalStorage,newValue);
  },true);
};
angular.module('app').service('localStorageService',['$window',localStorageWrapper]);
