angular.module('app').controller('DatesPageController',['$scope','$window','$timeout','tools','webservice','user','errorlog','parameters',
                                            function($scope,$window,$timeout,tools,webservice,user,errorlog,parameters) {
  var model=$scope.model;
  var script='public/reservationC';

  $window.scrollTo(0, 0);

  $scope.proceed = function() {
    if(angular.isUndefined(model.startdate)) {
      if($scope.isCatering()) {
        errorlog.error(tools.lang({
          'en':'Please select a date',
          'nl':'Selecteer een datum',
          'fr':'Veuillez s\u00E9lectionner une date'
        }));
      } else {
        errorlog.error(tools.lang({
          'en':'Please select an arrival date',
          'nl':'Selecteer een aankomstdatum',
          'fr':'Veuillez s\u00E9lectionner une date d\u0027arriv\u00E9e'
        }));
      }
    } else if (model.multipleDays!=='0' && angular.isUndefined(model.enddate)) {
      errorlog.error(tools.lang({
        'en':'Please select an end date',
        'nl':'Gelieve een einddatum te selecteren',
        'fr':'Veuillez s\u00E9lectionner une date de fin'
      }));
    } else {
      var startdate=tools.dateFromJava(model.startdate);
      var enddate=tools.dateFromJava(model.multipleDays==='0'?model.startdate:model.enddate);
      if(startdate.length!==10) {
        if($scope.isCatering()) {
          errorlog.error(tools.lang({
            'en':'Please select a date',
            'nl':'Selecteer een datum',
            'fr':'Veuillez s\u00E9lectionner une date'
          }));
        } else {
          errorlog.error(tools.lang({
            'en':'Please select an arrival date',
            'nl':'Selecteer een aankomstdatum',
            'fr':'Veuillez s\u00E9lectionner une date d\u0027arriv\u00E9e'
          }));
        }
      } else if(enddate.length!==10) {
        errorlog.error(tools.lang({
          'en':'Please select an end date',
          'nl':'Gelieve een einddatum te selecteren',
          'fr':'Veuillez s\u00E9lectionner une date de fin'
        }));
      } else if(model.enddate<model.startdate) {
        errorlog.error(tools.lang({
          'en':'End date must be after start date',
          'nl':'Einddatum moet na de startdatum komen',
          'fr':'Date de fin doit etre apres la date de début'
        }));
      } else {
        var requestId = $scope.getRequestId(tools.dateFromJava(model.startdate).substr(0,7));
        if($scope.isSpecificSale && requestId !== false && angular.isDefined(model.availabilityCache[requestId])) {
          var i,hsc,sDay,hrsDay,currentDay,nextMonthDay,sHrs,currentHrs,nextMonthHrs;
          var year=model.startdate.getFullYear();
          var month=model.startdate.getMonth()+1;
          var months=(model.enddate.getFullYear()*12+model.enddate.getMonth()+1)-(year*12+month);
          var days=Math.floor((model.enddate.getTime()-model.startdate.getTime())/(1000*60*60*24));
          var daysInMonth=new Date(year,month,0).getDate();
          var hotelAvailabilities=model.availabilityCache[requestId].hotelAvailabilities;
          var day=model.startdate.getDate()-(daysInMonth-tools.getLength(tools.first(hotelAvailabilities)))-1;
          var hrsLists = model.availabilityCache[requestId].hrsLists;
          var restaurantAvailabilities=model.availabilityCache[requestId].restaurantAvailabilities;
          var allRestaurantAvailabilities=model.availabilityCache[requestId].allRestaurantAvailabilities;

          for(i=0;i<months;i++) {
            if(month+1>12) {
              year++;
              month=1;
            } else {
              month++;
            }
            var nextMonthHrsLists = model.availabilityCache[$scope.getRequestId(year+'-'+tools.expandDigits(month,2))].hrsLists;
            for(var hrsList in nextMonthHrsLists) {
              var currentHrsList = hrsLists[hrsList];
              var nextMonthHrsList = nextMonthHrsLists[hrsList];
              if(angular.isUndefined(currentHrsList)) {
                currentHrsList = nextMonthHrsList;
              } else {
                currentHrsList.available = currentHrsList.available || nextMonthHrsList.available;
                for(hrsDay in nextMonthHrsList.days) {
                  currentDay = currentHrsList.days[hrsDay];
                  nextMonthDay = nextMonthHrsList.days[hrsDay];
                  if(angular.isUndefined(currentDay)) {
                    currentDay = nextMonthDay;
                  } else {
                    currentDay.available = currentDay.available || nextMonthDay.available;
                    currentDay.day = hrsDay;
                    for(sHrs in nextMonthDay.hrs) {
                      currentHrs = currentDay.hrs[sHrs];
                      nextMonthHrs = nextMonthDay.hrs[sHrs];
                      if(angular.isUndefined(currentHrs)) {
                        currentHrs = nextMonthHrs;
                      } else {
                        currentHrs.available = currentHrs.available || nextMonthHrs.available;
                        currentHrs.hrs = sHrs;
                        currentHrs.load = nextMonthHrs.load;
                        currentHrs.left = nextMonthHrs.left;
                      }
                      hrsLists[hrsList].days[hrsDay].hrs[sHrs] = currentHrs;
                    }
                  }
                  hrsLists[hrsList].days[hrsDay] = currentDay;
                }
              }
              hrsLists[hrsList] = currentHrsList;
            }
            var nextMonthAllRestaurantAvailabilities = model.availabilityCache[$scope.getRequestId(year+'-'+tools.expandDigits(month,2))].allRestaurantAvailabilities;
            for(var res in nextMonthAllRestaurantAvailabilities) {
              var currentRes = allRestaurantAvailabilities[res];
              var nextMonthRes = nextMonthAllRestaurantAvailabilities[res];
              if(angular.isUndefined(currentRes)) {
                currentRes = nextMonthRes;
              } else {
                currentRes.open = currentRes.open || nextMonthRes.open;
                currentRes.todayBeforeNow = currentRes.todayBeforeNow || nextMonthRes.todayBeforeNow;
                for(hrsDay in nextMonthRes.days) {
                  currentDay = currentRes.days[hrsDay];
                  nextMonthDay = nextMonthRes.days[hrsDay];
                  if(angular.isUndefined(currentDay)) {
                    currentDay = nextMonthDay;
                  } else {
                    currentDay.day = hrsDay;
                    for(sHrs in nextMonthDay.hrs) {
                      currentHrs = currentDay.hrs[sHrs];
                      nextMonthHrs = nextMonthDay.hrs[sHrs];
                      if(angular.isUndefined(currentHrs)) {
                        currentHrs = nextMonthHrs;
                      } else {
                        currentHrs.hrs = sHrs;
                      }
                      allRestaurantAvailabilities[res].days[hrsDay].hrs[sHrs] = currentHrs;
                    }
                  }
                  allRestaurantAvailabilities[res].days[hrsDay] = currentDay;
                }
              }
              allRestaurantAvailabilities[res] = currentRes;
            }
            var nextMonthRestaurantAvailabilities=model.availabilityCache[$scope.getRequestId(year+'-'+tools.expandDigits(month,2))].restaurantAvailabilities;
            for(sDay in nextMonthRestaurantAvailabilities) {
              restaurantAvailabilities[day]=nextMonthHotelAvailabilities[sDay];
            }
            var nextMonthHotelAvailabilities=model.availabilityCache[$scope.getRequestId(year+'-'+tools.expandDigits(month,2))].hotelAvailabilities;
            for(hsc in nextMonthHotelAvailabilities) {
              hotelAvailabilities[hsc]=hotelAvailabilities[hsc].concat(nextMonthHotelAvailabilities[hsc]);
            }
          }
          model.restaurantAvailabilities={};
          for(sDay in restaurantAvailabilities) {
            if(sDay>=model.startdateISO && sDay<=model.enddateISO) {
              model.restaurantAvailabilities[sDay]=restaurantAvailabilities[sDay];
            }
          }
          for(hsc in hotelAvailabilities) {
            hotelAvailabilities[hsc]=Math.min.apply(Math,hotelAvailabilities[hsc].slice(day,day+days));
          }
          model.hrsLists = hrsLists;
          model.hotelAvailabilities=hotelAvailabilities;
          model.allRestaurantAvailabilities=allRestaurantAvailabilities;
          $scope.fillInTemplateimageIdForSpecificSale();
        }
        $scope.incrementCurrentStepIndex();
        $scope.redirectToActivities();
      }
    }
  };

  model.page='dates';
  model.initializing=true;
  $scope.startdateOptions={
    minDate: new Date(),
    maxDate: new Date(2099,11,31),
    startingDay: 1,
    showWeeks: false,
    initDate: new Date()
  };
  $scope.enddateOptions={
    minDate: new Date(),
    maxDate: new Date(2099,11,31),
    startingDay: 1,
    showWeeks: false,
    initDate: new Date()
  };
  model.availabilityCache={};

  $scope.setMinAndMaxDateOptions = function(startdate,enddate) {
    var date=($scope.lastOrderHourPassed('00:15:00'))?(tools.addDays(new Date(),1)):(new Date());
    $scope.startdateOptions.minDate=new Date(Math.max(tools.dateToJava(startdate),date));
    $scope.startdateOptions.maxDate=tools.dateToJava(enddate);
    $scope.startdateOptions.initDate=new Date($scope.startdateOptions.minDate);
    $scope.enddateOptions.minDate=new Date(Math.max(tools.dateToJava(startdate),date));
    $scope.enddateOptions.maxDate=tools.dateToJava(enddate);
    $scope.enddateOptions.initDate=new Date($scope.enddateOptions.minDate);
  };

  $scope.getRequestId = function(yearAndMonth) {
    var requestId=false;
    if($scope.fixedDateIntervals!==null && model.fixedDateIntervalId!==null && angular.isDefined(model.fixedDateIntervalId)) {
      requestId=yearAndMonth+model.fixedDateIntervalId;
    } else if($scope.fixedDateIntervals===null) {
      requestId=yearAndMonth;
    }
    return requestId;
  };

  $scope.enddateIsAvailable = function(available,date) {
    if(available) {
      var yearAndMonth,requestId,intervalStart;
      var dayBeforeEnddate=new Date(date);
      dayBeforeEnddate.setDate(dayBeforeEnddate.getDate()-1);
      if(angular.isDefined(model.startdate)) {
        available=tools.dateFromJava(date)>tools.dateFromJava(model.startdate) && date.valueOf()<=(model.startdate.valueOf() + (1000 * 3600 * 24 * 31));
        intervalStart=model.startdate;
      } else {
        intervalStart=dayBeforeEnddate;
      }
      for(var d=new Date(intervalStart.getTime());d<=dayBeforeEnddate;d.setDate(d.getDate()+1)) {
        yearAndMonth=tools.dateFromJava(d).substr(0,7);
        requestId=$scope.getRequestId(yearAndMonth);
        available=available && (requestId in model.availabilityCache) && (tools.dateFromJava(d) in model.availabilityCache[requestId]) && model.availabilityCache[requestId][tools.dateFromJava(d)].available;
        if(!available) {
          break;
        }
      }
    }
    return available;
  };

  var pendingRequests={};
  $scope.getSpecificSaleAvailabilities = function(yearAndMonth,requestId) {
    if(!(requestId in pendingRequests)) {
      var today=new Date();
      var jStartdate,jEnddate;
      jStartdate=tools.dateToJava(yearAndMonth+'-01');
      if(jStartdate<today) {
        jStartdate=today;
      }
      jEnddate=tools.dateToJava(yearAndMonth+'-01');
      jEnddate.setMonth(jEnddate.getMonth()+1);
      if(jEnddate<today) {
        pendingRequests[requestId]=true;
      } else {
        pendingRequests[requestId]=webservice.call('getSpecificSaleAvailabilities',{
          'lang':user.lang,
          'startdate':tools.dateFromJava(jStartdate),
          'enddate':tools.dateFromJava(jEnddate),
          'ffo':$scope.ffo,
          'fixedDateInterval':requestId.length>7?$scope.fixedDateIntervals[model.fixedDateIntervalId]:false,
          'formbutton':$scope.isSpecificSale?model.specificSaleFormbuttonId:false,
          'contactexternalids':tools.toArray(model.contactexternalids)
        },script,model).then(function(response) {
          model.availabilityCache[requestId]=response;
          $scope.$broadcast('refreshDatepickers');
        });
      }
    }
  };

  $scope.startdateOptions.dateDisabled=function(data) {
    var result=true;
    var today=new Date();
    var yearAndMonth=tools.dateFromJava(data.date).substr(0,7);
    var requestId=$scope.getRequestId(yearAndMonth);
    if((requestId && requestId in model.availabilityCache) || data.mode==='year') {
      if(data.mode==='year') {
        result=data.date.getFullYear()<today.getFullYear();
      } else if(data.mode==='month') {
        var availabilities=model.availabilityCache[requestId];
        result=true;
        for(var date in availabilities) {
          if(availabilities[date].available) {
            result=false;
            break;
          }
        }
      } else {
        result=!model.availabilityCache[requestId][tools.dateFromJava(data.date)].available;
      }
    } else if(requestId) {
      $scope.getSpecificSaleAvailabilities(yearAndMonth,requestId);
    }
    return result;
  };
  $scope.enddateOptions.dateDisabled=function(data) {
    var today=new Date();
    var date=tools.dateToJava(tools.dateFromJava(data.date));
    var yearAndMonth=tools.dateFromJava(date).substr(0,7);
    var requestId=$scope.getRequestId(yearAndMonth);
    var mode=data.mode;
    var available=false;
    if((requestId && requestId in model.availabilityCache) || mode==='year') {
      if(mode==='year') {
        available=date.getFullYear()>=today.getFullYear();
      } else if(mode==='month') {
        available=tools.dateFromJava(date).substr(0,7)>=tools.dateFromJava(today).substr(0,7);
        if(available) {
          var enddate=new Date(date.getTime());
          enddate.setMonth(enddate.getMonth()+1);
          for(var d=new Date(date.getTime());d<enddate;d.setDate(d.getDate()+1)) {
            available=$scope.enddateIsAvailable(true,d);
            if(available) {
              break;
            }
          }
        }
      } else {
        available=$scope.enddateIsAvailable(tools.dateFromJava(date)>tools.dateFromJava(today),date);
      }
    } else if(requestId) {
      $scope.getSpecificSaleAvailabilities(yearAndMonth,requestId);
    }
    return !available;
  };

  $scope.enddateOptions.customClass = function(data) {
    var classes = [];
    var today = new Date();
    var date = tools.dateToJava(tools.dateFromJava(data.date));
    var yearAndMonth=tools.dateFromJava(date).substr(0,7);
    var requestId = $scope.getRequestId(yearAndMonth);

    if(tools.dateFromJava(date) === tools.dateFromJava(today)) {
      classes.push('current-day');
    }

    if(requestId && requestId in model.availabilityCache) {
      if(angular.isDefined(model.enddate) && tools.dateFromJava(model.enddate) === tools.dateFromJava(date)) {
        classes.push('active');
        classes.push('last-selected');
        classes.push('first-selected');
      }
      if($scope.enddateIsAvailable(tools.dateFromJava(date)>tools.dateFromJava(today),date)) {
        classes.push('available');
        classes.push('start-day');
      }
    } else if(requestId && data.mode!=='year') {
      $scope.getSpecificSaleAvailabilities(yearAndMonth,requestId);
    }
    return classes;
  };

  $scope.startdateOptions.customClass = function(data) {
    var classes = [];
    var today = new Date();
    today.setHours(0,0,0,0);
    var date = tools.dateToJava(tools.dateFromJava(data.date));
    var yearAndMonth=tools.dateFromJava(data.date).substr(0,7);
    var requestId = $scope.getRequestId(yearAndMonth);
    var previousDate = tools.dateToJava(yearAndMonth+'-01');
    previousDate.setMonth(previousDate.getMonth()-1);
    var previousRequestId = $scope.getRequestId(tools.dateFromJava(previousDate).substr(0,7));
    if(date.getTime() === today.getTime()) {
      classes.push('current-day');
    }

    if(requestId && requestId in model.availabilityCache) {
      var availabilities = model.availabilityCache[requestId];
      if(previousRequestId in model.availabilityCache) {
        var dayParams,day,previousDayParams;
        var extraDays=tools.clone(model.availabilityCache[previousRequestId].extraDays);
        for(day in extraDays) {
          previousDayParams=extraDays[day];
          dayParams=availabilities[day];
          dayParams.isDate=dayParams.isDate||previousDayParams.isDate;
          dayParams.isEnddate=dayParams.isEnddate||previousDayParams.isEnddate;
          dayParams.isStartdate=dayParams.isStartdate||previousDayParams.isStartdate;
          dayParams.available=dayParams.available||previousDayParams.available;
        }
      }
      var javaDate = tools.dateFromJava(data.date);
      if(availabilities !== false && javaDate in availabilities) {
        var availability = availabilities[javaDate];
        if(!availability.available) {
          classes.push('disabled');
        }
        if(availability.isDate) {
          classes.push('available');
        }
        if(angular.isDefined(model.startdate)) {
          if(model.startdate.getTime() === date.getTime()) {
           classes.push('active');
           classes.push('first-selected');
           if(model.multipleDays === '0') {
             classes.push('last-selected');
           }
          } else {
            var startdateAvailability = availabilities[tools.dateFromJava(model.startdate)];
            if(angular.isUndefined(startdateAvailability) && (previousRequestId in model.availabilityCache)) {
              startdateAvailability = model.availabilityCache[previousRequestId][tools.dateFromJava(model.startdate)];
            }
            if(angular.isDefined(startdateAvailability) && ('fixedEnddates' in startdateAvailability)) {
              var enddate = tools.dateToJava(tools.first(startdateAvailability.fixedEnddates).enddate);
              if(enddate.getTime() === date.getTime()) {
                classes.push('active');
                classes.push('last-selected');
              } else if(date >= model.startdate && date <= enddate) {
                classes.push('active');
              }
            }
          }
        }
        if(availability.isStartdate) {
          classes.push('first','start-day');
          var previousJavaDayDate = tools.dateFromJava(new Date(date.getTime() - 60 * 60 * 24 * 1000));
          if(previousJavaDayDate in availabilities) {
            var previousAvailabilities = availabilities[previousJavaDayDate];
            if(previousAvailabilities.isDate) {
              classes.push('middle');
            }
          }
        }
        if(availability.isEnddate) {
          classes.push('last');
          var nextJavaDayDate = tools.dateFromJava(new Date(date.getTime() + 60 * 60 * 24 * 1000));
          var nextAvailability;
          if(nextJavaDayDate in availabilities) {
            nextAvailability = availabilities[nextJavaDayDate];
          } else if(nextJavaDayDate in availabilities.extraDays) {
            nextAvailability = availabilities.extraDays[nextJavaDayDate];
          }
          if(angular.isDefined(nextAvailability) && nextAvailability.isDate) {
            classes.push('middle');
          }
        }
      }
    } else if(requestId && data.mode!=='year') {
      $scope.getSpecificSaleAvailabilities(yearAndMonth,requestId);
    }
    return classes;
  };

  $scope.$watch('model.startdate.getTime()',function(newVal) {
    if($scope.fixedDateIntervals===null) {
      if('FFO' in parameters && parameters.FFO[$scope.ffo].field7==='1') {
        model.multipleDays='1';
      }
      if(model.startdate) {
        var startdateValue=model.startdate.valueOf();
        var dayValue=1000 * 3600 * 24;
        $scope.enddateOptions.minDate=new Date(startdateValue + dayValue);
        $scope.enddateOptions.maxDate=new Date(startdateValue + (dayValue * 31));
        if(!model.enddate || model.startdate >= model.enddate) {
          model.enddate=(model.multipleDays==='1')?(new Date(startdateValue + dayValue)):(new Date(startdateValue));
        } else if((model.enddate.getTime() - model.startdate.getTime()) / dayValue > 31) {
          model.enddate = new Date(startdateValue + (dayValue * 31));
        }
      }
      $scope.onDateChange('startdate',newVal);
    } else {
      if(model.startdate && model.fixedDateIntervalId!==null) {
        var startdate=tools.dateFromJava(model.startdate);
        var requestId=startdate.substr(0,7)+model.fixedDateIntervalId;
        if(requestId in model.availabilityCache && startdate in model.availabilityCache[requestId]) {
          model.enddate=tools.dateToJava(tools.first(model.availabilityCache[requestId][startdate].fixedEnddates).enddate);
        }
      }
      $scope.onDateChange('startdate',newVal);
    }
    $scope.$broadcast('refreshDatepickers');
  });
  $scope.$watch('model.enddate.getTime()',function(newVal) {
    if('FFO' in parameters && parameters.FFO[$scope.ffo].field7==='1') {
      model.multipleDays='1';
    }
    $scope.onDateChange('enddate',newVal);
    $scope.$broadcast('refreshDatepickers');
  });
  $scope.$watch('model.fixedDateIntervalId',function(newVal,oldVal) {
    model.multipleDays=($scope.fixedDateIntervals===null || angular.isUndefined(model.fixedDateIntervalId) || !(model.fixedDateIntervalId in $scope.fixedDateIntervals) || $scope.fixedDateIntervals[model.fixedDateIntervalId].enddateOffset===0)?'0':'1';
    if(newVal!==oldVal) {
      delete model.startdate;
      delete model.enddate;
    }
    $scope.$broadcast('refreshDatepickers');
  });
  $timeout(function() {
    model.initializing=false;
  });

  $scope.loaded.then(function() {
    if(parameters.FFO[$scope.ffo].field2!=='') {
      $scope.setMinAndMaxDateOptions(parameters.FFO[$scope.ffo].field2,parameters.FFO[$scope.ffo].field3);
    } else if(parameters.FFO[$scope.ffo].field9!=='') {
      $scope.setMinAndMaxDateOptions(tools.dateFromJava($scope.startdateOptions.minDate),tools.dateFromJava($scope.startdateOptions.maxDate));
    }

    $scope.setCurrentStepIndexToMin();
    $scope.fillInTemplateimageIdForSpecificSale();
  });
}]);
