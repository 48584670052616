
angular.module('app').directive('ngEnter', function() {
  return function(scope, element, attrs) {
    element.bind('keydown keypress', function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          scope.$eval(attrs.ngEnter);
        });
        event.preventDefault();
      }
    });
  };
});

angular.module('app').directive('autoSubmitForm', ['$interpolate','config', function($interpolate,config) {
  return {
    replace: true,
    scope: {
      formData: '='
    },
    template: '',
    link: function($scope, element, $attrs) {
      $scope.$on($attrs.event, function(event, data) {
        var formString='<form action="'+config.get('sipsUrl')+'" method="POST">';
        for(var i in data) {
          formString+='<input name="'+i+'" type="hidden" value="'+data[i]+'"/>';
        }
        formString+='</form>';
        var form = $interpolate(formString)($scope);
        angular.element(form).appendTo('body').submit();
      });
    }
  };
}]);
